.sidebar {
  width: 14.4rem;
  height: 100%;
  min-height: calc(100vh - 78px);
  position: relative;
  border-right: 2px solid #e6e8f3;
  // transition: all 0.5s ease-in-out;
  //z-index: 1000;
  background-color: #fff;


   // Enable vertical scrolling
   overflow-y: auto;
   overflow-x: hidden;
 
   /* Scrollbar styling for webkit browsers (Chrome, Safari) */
   &::-webkit-scrollbar {
     width: 8px;
   }
 
   &::-webkit-scrollbar-thumb {
     background-color: #888;
     border-radius: 4px;
   }
 
   &::-webkit-scrollbar-track {
     background: #f1f1f1;
   }
 

  .logo-image {
    padding-left: 0.875rem;
    display: block;
  }

  svg {
    width: 1.5rem;
    height: 1.25rem;
  }

  button {
    position: absolute;
    top: 0.5rem;
    right: 0.875rem;
  }

  button:focus {
    box-shadow: 0 0 0 0;
  }

  a {
    color: #0053a0;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }
}

.sidebar.sidebar-collapsed {
  width: 4.5rem;

  .logo-image {
    display: none;
  }

  .sidebar-links {
    margin-top: 3.125rem;
  }
}

/* Responsive adjustments for smaller screens */
@media (max-height: 600px) {
  .sidebar {
    max-height: 100vh;
  }
}


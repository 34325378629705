.rese-alert-suppression-request-container {
    padding: 1.5rem;

    .alert-info-text {
        font-weight: 700;
        margin-bottom: 1.5rem;
    }
    .alert-info-error-text {
        padding-top: 0.5rem;
    }
    .alert-configuraion-cards {
        // gap: 1.5rem;
        padding-left: 15px;
        margin-right: 0.5rem;
        justify-content: space-between;
        // justify-items: stretch;

        .p-card .p-card-body {
            text-align: center;
        }

        .p-card .p-card-title {
            font-size: 1.1rem;
            // color: white;
        }

        .p-card .p-card-subtitle {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Number of lines before truncating */
            -webkit-box-orient: vertical;
            white-space: normal; /* Allow text to wrap */
            position: relative;
        }

        .p-card .p-card-subtitle:hover::after {
            content: attr(title);
            max-width: 200px;
            word-wrap: break-word;
            white-space: normal; /* Allow text to wrap */
            position: absolute;
            background-color: #333;
            color: #fff;
            padding: 5px;
            border-radius: 3px;
            z-index: 10;
            top: 100%; /* Position the tooltip below the subtitle */
            left: 0;
            transform: translateY(5px); /* Add some space between the subtitle and the tooltip */
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
            visibility: visible; /* Ensure the tooltip is visible */
            opacity: 1; /* Ensure the tooltip is fully opaque */
            transition: opacity 0.2s ease-in-out; /* Smooth transition for the tooltip */
        }

        .custom-card {
            flex: 0 1 auto;
            height: 110px;
            width: 300px;
            border: 1px solid black;
            box-sizing: border-box;
            // width: 100%;
            // background-color: #00529F
        }
    }
    form {
        .suppression-date-container {
            margin-top: 1.5rem;
            .date {
                display: flex;
            }
        }
        .content-padding {
            padding-left: 6rem !important;
        }
        .input-field {

             label {
                font-weight: 500;
                line-height: 2rem;
             }

             & > div {
                padding: 0;
                margin-top: 0.25rem;
             }

             & > div:nth-child(2) {
                display: flex;
                // justify-content: center;
                margin-bottom: 1.5rem;

                .p-component.p-dropdown, .p-component.p-inputtext, .p-calendar {
                    width: 15rem;
                    height: 2.5rem;
                }

                strong {
                    font-size: 0.875rem;
                    color: #7f7f7f;
                }
             }
            & > button {
                margin-right: 1.25rem;
            }
        }
    }
}
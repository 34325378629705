.rese-alert-suppression-logs-container {
    padding: 1.5rem ;

    .logs-container-top {
        margin-bottom: 1rem;

        .input-field {
            display: flex;
            align-items: center;
            margin: 4px 0;
    
            label {
                padding-top: 4px;
                width: 130px;
            }
    
            .p-calendar {
                // margin-left: 20px;
                width: 248px;
            }
        }
        .get-logs-buttons {
            margin-top: 0.5rem;
        }
    }

    table {

        tr > th:nth-child(1) {
            max-width: 100px;
        }
        tr > td:nth-child(1) {
            padding: 0;
        }

        tr > th:nth-child(2), th:nth-child(12) {
            max-width: 95px;
        }
        tr > th:nth-child(3), tr > td:nth-child(3), tr > th:nth-child(14), tr > td:nth-child(14) {
            max-width: 110px;
        }
        tr > th:nth-child(4), tr > td:nth-child(4), tr > th:nth-child(5), tr > td:nth-child(5), tr > th:nth-child(13), tr > td:nth-child(13) {
            max-width: 120px;
        }
        tr > th:nth-child(6), tr > th:nth-child(7), tr > th:nth-child(8), tr > th:nth-child(9) {
            max-width: 105px;
        }

    }
    .actions {
        display: flex;
        justify-content: space-between;
        // margin-top: 1rem;
    }

}